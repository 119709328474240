.hvac-program-header {
    display: flex;
    justify-content: space-between;

    .hvac-p-sm {
        color: $black;
    }
}

.hvac-account-admin-heading-default {
    padding-top: pad(3);
    height: 60px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hvac-p-sm {
        color: $black;
    }

    .hvac-back-arrow {
        transform: rotate(180deg);
        right: 10px;
    }
}


abbr.hvac-program-header-required-mark {
    color: $error;
    text-decoration: none;
    padding: pad(0.5);
}

.hvac-account-admin-heading {
    margin-bottom: $rhythm * 5;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: -($padding * 5);
        right: -($padding * 5);
        bottom: -20px;
        height: 1px;
        background-color: $border-color;
    }
}
